import { createApi } from '@reduxjs/toolkit/query/react';
import StateName from 'helpers/stateNames';
import { RequestAPI } from 'helpers/requests';
import { axiosBaseQuery } from 'helpers/axios';
import {
  ICompaniesByStatusResponse,
  IFinancesIncomeResponse,
  INewCompaniesByYearsResponse,
  ICompaniesByBusinessTypesResponse,
  INewCompaniesByMonthsResponse,
  ICompaniesStatusByYearsResponse,
  ICompaniesRestoredByYearsResponse,
  ICompaniesByRegionsResponse,
  IGCPResponse,
  IProductsByBranchResponse,
  IProductsByGcpResponse,
  IGcpGrpResponse,
  IGtinGrpResponse,
} from 'types/response';
import { dataToSnakeCase } from 'helpers/register';
import { vsprintf } from 'sprintf-js';
import {
  ICompaniesByStatusArgs,
  IFinancesIncomeArgs,
  INewCompaniesByYearsArgs,
  INewCompaniesByMonthsArgs,
  ICompaniesByBusinessTypesArgs,
  IProductsByBranchArgs,
  ICompaniesStatusByYearsArgs,
  ICompaniesRestoredByYearsArgs,
  ICompaniesByRegionsArgs,
} from './statisticsTypes';

const statisticsApi = createApi({
  reducerPath: StateName.STATISTICS_API,
  baseQuery: axiosBaseQuery(),
  // Время жизни данных в кеше, когда они не используются в сек. Не мало ли 0?!
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    companiesByStatus: build.query<ICompaniesByStatusResponse, ICompaniesByStatusArgs | undefined>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_COMPANIES_BY_STATUS,
          params: dataToSnakeCase(args),
        };
      },
    }),
    financesIncome: build.query<IFinancesIncomeResponse, IFinancesIncomeArgs>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_FINANCES_INCOME,
          params: dataToSnakeCase(args),
        };
      },
    }),
    newCompaniesByYears: build.query<INewCompaniesByYearsResponse, INewCompaniesByYearsArgs>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_NEW_COMPANIES_BY_YEARS,
          params: dataToSnakeCase(args),
        };
      },
    }),
    newCompaniesByMonths: build.query<INewCompaniesByMonthsResponse, INewCompaniesByMonthsArgs>({
      query({ year, ...args }) {
        return {
          url: vsprintf(RequestAPI.STATISTICS_NEW_COMPANIES_BY_MONTHS, [ year ]),
          params: dataToSnakeCase({ ...args }),
        };
      },
    }),
    companiesByBusinessTypes: build.query<ICompaniesByBusinessTypesResponse, ICompaniesByBusinessTypesArgs | undefined>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_COMPANIES_BY_BUSINESS_TYPES,
          params: dataToSnakeCase(args),
        };
      },
    }),
    companiesStatusByYears: build.query<ICompaniesStatusByYearsResponse, ICompaniesStatusByYearsArgs>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_COMPANIES_STATUS_BY_YEARS,
          params: dataToSnakeCase(args),
        };
      },
    }),
    companiesRestoredByYears: build.query<ICompaniesRestoredByYearsResponse, ICompaniesRestoredByYearsArgs>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_COMPANIES_RESTORED_BY_YEARS,
          params: dataToSnakeCase(args),
        };
      },
    }),
    companiesByRegions: build.query<ICompaniesByRegionsResponse, ICompaniesByRegionsArgs>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_COMPANIES_BY_REGIONS,
          params: dataToSnakeCase(args),
        };
      },
    }),
    gcp: build.query<IGCPResponse, void>({
      query() {
        return {
          url: RequestAPI.STATISTICS_GCP,
        };
      },
    }),
    productsByBranch: build.query<IProductsByBranchResponse, IProductsByBranchArgs>({
      query({ year }) {
        return {
          url: vsprintf(RequestAPI.STATISTICS_PRODUCTS_BY_BRANCH, [ year ]),
        };
      },
    }),
    productsByGcp: build.query<IProductsByGcpResponse, void>({
      query() {
        return {
          url: RequestAPI.STATISTICS_PRODUCTS_BY_GCP,
        };
      },
    }),
    gcpGrp: build.query<IGcpGrpResponse, void>({
      query() {
        return {
          url: RequestAPI.STATISTICS_GCP_GRP,
        };
      },
    }),
    gtinGrp: build.query<IGtinGrpResponse, void>({
      query() {
        return {
          url: RequestAPI.STATISTICS_GTIN_GRP,
        };
      },
    }),
  }),
});

export {
  statisticsApi,
};
