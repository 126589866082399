import { ITabsList } from 'components/common/tabs/TabsTypes';
import { IStatus, StatusState } from 'helpers/statuses';
import { ICompaniesStatusesCountResponse } from '../types/response';

enum CompanyStatusAlias {
  ALL = '',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  LESS_30 = 'less_30',
  DEBTOR = 'debtor',
  EXCLUDED = 'excluded',
}

const CompanyDetailAlias = {
  INFO: 'info',
  REQUISITES: 'requisites',
  GLNS: 'glns',
  PRODUCTS: 'products',
  CONTRACTS: 'contracts',
  HISTORY: 'history',
  DOCS: 'docs',
  SYNC: 'sync',
} as const;
type ICompanyDetailAlias = typeof CompanyDetailAlias[keyof typeof CompanyDetailAlias];

const CompanyProductDetailAlias = {
  INFO: 'info',
  SYNC: 'sync',
} as const;
type ICompanyProductDetailAlias = typeof CompanyProductDetailAlias[keyof typeof CompanyProductDetailAlias];

type ICompanyStatus = IStatus<CompanyStatusAlias>;
const companyStatuses: ICompanyStatus[] = [
  {
    label: {
      single: 'Активный',
      multi: 'Активные',
    },
    alias: CompanyStatusAlias.ACTIVE,
    state: StatusState.ACTIVE,
  },
  {
    label: {
      single: 'Меньше 30-ти дней',
      multi: 'Меньше 30-ти дней',
    },
    alias: CompanyStatusAlias.LESS_30,
    state: StatusState.PARTIALLY_ACTIVE,
  },
  {
    label: {
      single: 'Должник',
      multi: 'Должники',
    },
    alias: CompanyStatusAlias.DEBTOR,
    state: StatusState.INACTIVE,
  },
  {
    label: {
      single: 'Исключённый',
      multi: 'Исключённые',
    },
    alias: CompanyStatusAlias.EXCLUDED,
    state: StatusState.INACTIVE,
  },
  {
    label: {
      single: 'Неактивный',
      multi: 'Неактивные',
    },
    alias: CompanyStatusAlias.INACTIVE,
    state: StatusState.INACTIVE,
  },
];

const companyStatusAll: ICompanyStatus = {
  label: {
    single: 'Все компании',
    multi: 'Все компании',
  },
  alias: CompanyStatusAlias.ALL,
  state: StatusState.ACTIVE,
};

const companyStatusesWithAll: ICompanyStatus[] = [
  companyStatusAll,
  ...companyStatuses,
];

const companyDetailAliases: ITabsList<ICompanyDetailAlias> = [
  {
    label: 'Инфо. о компании',
    value: CompanyDetailAlias.INFO,
  },
  {
    label: 'Реквизиты',
    value: CompanyDetailAlias.REQUISITES,
  },
  {
    label: 'GLNs',
    value: CompanyDetailAlias.GLNS,
  },
  {
    label: 'Банк GTIN',
    value: CompanyDetailAlias.PRODUCTS,
  },
  {
    label: 'Финансы',
    value: CompanyDetailAlias.CONTRACTS,
  },
  {
    label: 'Заметки',
    value: CompanyDetailAlias.HISTORY,
  },
  {
    label: 'Документы',
    value: CompanyDetailAlias.DOCS,
  },
  {
    label: 'Синхронизация',
    value: CompanyDetailAlias.SYNC,
  },
];

const companyProductDetailAliases: ITabsList<ICompanyProductDetailAlias> = [
  {
    label: 'Инфо. о продукте',
    value: CompanyProductDetailAlias.INFO,
  },
  {
    label: 'Синхронизация',
    value: CompanyProductDetailAlias.SYNC,
  },
];

type IStatusesCounts = ICompaniesStatusesCountResponse | undefined;
const getCompaniesCountByStatusAlias = (statusesCounts: IStatusesCounts, statusAlias: CompanyStatusAlias): number => statusesCounts
  ?.find(({ status }) => (statusAlias === CompanyStatusAlias.ALL ? status as string === 'all' : statusAlias === status))?.count || 0;
const getCompaniesStatusByAlias = (statusAlias: CompanyStatusAlias) => companyStatusesWithAll
  ?.find(({ alias }) => alias === statusAlias) || null;

// TODO Норм ли через pathname.split?!
const getCompaniesDetailAlias = (pathname: string): string | undefined => pathname.split('/')?.[3];
const getCompaniesProductsDetailAlias = (pathname: string): string | undefined => pathname.split('/')?.[5];

export type {
  ICompanyStatus,
};

export {
  CompanyStatusAlias,
  CompanyDetailAlias,
  companyStatuses,
  companyStatusAll,
  companyStatusesWithAll,
  companyDetailAliases,
  getCompaniesCountByStatusAlias,
  getCompaniesDetailAlias,
  getCompaniesProductsDetailAlias,
  getCompaniesStatusByAlias,
  CompanyProductDetailAlias,
  companyProductDetailAliases,
};
