import styled from 'styled-components/macro';

const ToastsWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  width: 300px;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
  gap: 10px;
  z-index: 1000;
`;

export {
  ToastsWrap,
};
