import { IStatus, StatusState } from './statuses';

const SyncStatus = {
  PENDING: 0,
  SYNCED: 1,
  PENDING_DESYNC: 2,
  DESYNCED: 3,
  PENDING_DELETE: 4,
  DELETED: 5,
  STALE: 6,
  FAILED: 7,
} as const;
type ISyncStatus = typeof SyncStatus[keyof typeof SyncStatus];

const syncStatuses: IStatus<ISyncStatus>[] = [
  {
    label: {
      single: 'Ожидает синхронизации',
      multi: 'Ожидают синхронизации',
    },
    alias: SyncStatus.PENDING,
    state: StatusState.PENDING,
  },
  {
    label: {
      single: 'Синхронизирован',
      multi: 'Синхронизированы',
    },
    alias: SyncStatus.SYNCED,
    state: StatusState.ACTIVE,
  },
  {
    label: {
      single: 'Ожидает десинхронизации',
      multi: 'Ожидают десинхронизации',
    },
    alias: SyncStatus.PENDING_DESYNC,
    state: StatusState.PENDING,
  },
  {
    label: {
      single: 'Десинхронизирован',
      multi: 'Десинхронизированы',
    },
    alias: SyncStatus.DESYNCED,
    state: StatusState.INACTIVE,
  },
  {
    label: {
      single: 'Ожидает удаления',
      multi: 'Ожидают удаления',
    },
    alias: SyncStatus.PENDING_DELETE,
    state: StatusState.PENDING,
  },
  {
    label: {
      single: 'Удалён',
      multi: 'Удалены',
    },
    alias: SyncStatus.DELETED,
    state: StatusState.INACTIVE,
  },
  {
    label: {
      single: 'Устарел',
      multi: 'Устарели',
    },
    alias: SyncStatus.STALE,
    state: StatusState.PARTIALLY_ACTIVE,
  },
  {
    label: {
      single: 'Синхронизация не удалась',
      multi: 'Синхронизация не удалась',
    },
    alias: SyncStatus.FAILED,
    state: StatusState.INACTIVE,
  },
];

export type {
  ISyncStatus,
};

export {
  SyncStatus,
  syncStatuses,
};
