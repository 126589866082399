import React from 'react';
import Portal from 'components/common/portal/Portal';
import { PortalSelector } from 'helpers/portals';
import { isEmpty } from 'ramda';
import useSelector from 'hooks/redux/useSelector';
import StateName from 'helpers/stateNames';
import ToastsItem from 'components/common/toasts/item/ToastsItem';
import { IToastItemProps } from 'components/common/toasts/item/ToastsItemTypes';
import { ToastsWrap } from './ToastsStyles';

function Toasts() {
  const toastsState = useSelector((state) => state[StateName.TOASTS]);

  const isShow = !isEmpty(toastsState);

  if (isShow) {
    return (
      <Portal selector={PortalSelector.TOASTS}>
        <ToastsWrap>
          {toastsState.map((item: IToastItemProps) => {
            const {
              title,
              description,
              type,
              id,
            } = item;

            return (
              <ToastsItem
                title={title}
                description={description}
                type={type}
                id={id}
                key={id}
              />
            );
          })}
        </ToastsWrap>
      </Portal>
    );
  }

  return null;
}

export default Toasts;
