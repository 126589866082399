import React from 'react';
import useTimeout from 'hooks/core/useTimeout';
import useDispatch from 'hooks/redux/useDispatch';
import { toastsSlice } from 'components/services/toasts/toastsService';
import Alt from 'helpers/alt';
import { IToastItemProps, ToastItemType } from './ToastsItemTypes';
import {
  ToastsItemWrap,
  ToastsItemCaption,
  ToastsItemCaptionDescription,
  ToastsItemCaptionTitle,
  ToastsItemMark,
} from './ToastsItemStyles';

function ToastsItem(props: IToastItemProps) {
  const {
    title = Alt.TOAST_TITLE,
    description,
    type = ToastItemType.INFO,
    id,
  } = props;

  const dispatch = useDispatch();

  const handleRemove = () => dispatch(toastsSlice.actions.removeById(id));

  useTimeout(() => handleRemove(), 30000);

  return (
    <ToastsItemWrap onClick={handleRemove} data-tooltip="Нажмите на уведомление, чтобы закрыть его">
      <ToastsItemMark type={type} />
      <ToastsItemCaption>
        <ToastsItemCaptionTitle>{title}</ToastsItemCaptionTitle>
        {description ? (
          <ToastsItemCaptionDescription dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </ToastsItemCaption>
    </ToastsItemWrap>
  );
}

export default ToastsItem;
