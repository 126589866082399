import {
  flatten,
  is,
  isEmpty,
  isNil,
  map,
  toPairs,
} from 'ramda';
import { AxiosResponse } from 'axios';
import TextSymbol from 'helpers/textSymbols';

const errorKeyName = {
  name: 'Название (имя, вторичная торговая марка)',
  description: 'Описание',
  staffs: 'Контакты',
  phone: 'Телефон',
  addresses: 'Адреса',
  postcode: 'Почтовый индекс',
  email: 'Электронная почта',
  street: 'Улица',
  houseNumber: 'Номер дома',
  district: 'Район',
  tin: 'ИНН (ПИНФЛ)',
  ownership: 'Форма собственности',
  tradeMark: 'Торговая марка',
  package: 'Тип упаковки',
  quantity: 'Количество',
  measurement: 'Ед. измерения',
  targetMarket: 'Целевые рынки',
  eancodeType: 'Тип GTIN',
  excel: 'Excel',
  packages: 'Упаковки',
  deliveryType: 'Способ доставки',
  businessType: 'Категория компании',
  paymentType: 'Вид взноса',
  company: 'Наименование компании',
  productType: 'Brick code',
  detail: 'Неклассифицированные ошибки',
  nonFieldErrors: 'Неклассифицированные ошибки',
} as const;
type IErrorKey = keyof typeof errorKeyName;

const formatKey = (key: string, depth: number) => `${TextSymbol.DOT.repeat(depth)} <b>${errorKeyName[key as IErrorKey] ?? key}:</b>`;

const processValue = (value: unknown, key: string | null, depth = 0, isParentObject = false): string[] => {
  if (isNil(value)) return [];

  if (typeof value === 'string') {
    if (key) return [ `${formatKey(key, depth)}`, `${TextSymbol.DOT.repeat(depth)} ${value}` ];
    return [ `${TextSymbol.DOT.repeat(depth)} ${value}` ];
  }

  if (Array.isArray(value)) {
    if (value.every((item) => typeof item === 'string')) {
      return key
        ? [ `${formatKey(key, depth)}`, ...value.map((v) => `${TextSymbol.DOT.repeat(depth)} ${v}`) ]
        : value.map((v) => `${TextSymbol.DOT.repeat(depth)} ${v}`);
    }

    const withRowTitle = !isNil(key) && [
      errorKeyName.excel,
      errorKeyName.packages,
    ].some((item) => (item === key) && (key !== errorKeyName.packages || isParentObject));

    if (withRowTitle) {
      const rows = value
        .map((item, index) => {
          const rowNumber = key === errorKeyName.excel ? index + 4 : index + 3;
          const rowErrors = processValue(item, null, depth + 2, false);
          return rowErrors.length > 0
            ? [ `${TextSymbol.DOT.repeat(depth + 1)} <b>Ряд №${rowNumber}:</b>`, ...rowErrors ]
            : [];
        })
        .filter((row) => row.length > 0);

      return rows.length > 0 ? [ `${formatKey(key, depth)}`, ...flatten(rows) ] : [];
    }

    return key
      ? [ `${formatKey(key, depth)}`, ...flatten(map((item) => processValue(item, null, depth + 1, false), value)) ]
      : flatten(map((item) => processValue(item, null, depth, false), value));
  }

  if (is(Object, value)) {
    return key
      ? [ `${formatKey(key, depth)}`, ...flatten(
        map(
          ([ subKey, subValue ]) => processValue(
            subValue,
            errorKeyName[subKey as IErrorKey] ?? subKey,
            depth + 1,
            key === errorKeyName.excel,
          ),
          toPairs(value as Record<string, unknown>),
        ),
      ) ]
      : flatten(
        map(
          ([ subKey, subValue ]) => processValue(
            subValue,
            errorKeyName[subKey as IErrorKey] ?? subKey,
            depth,
            false,
          ),
          toPairs(value as Record<string, unknown>),
        ),
      );
  }

  return [];
};

const defaultErrorSerializer = (data: AxiosResponse['data']): string => {
  const errors = isNil(data) ? [] : processValue(data, null);
  return isEmpty(errors) ? 'Неизвестная ошибка' : errors.join('<br>');
};

export {
  defaultErrorSerializer,
};
